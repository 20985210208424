import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Urban Fade
			</title>
			<meta name={"description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:title"} content={"Urban Fade"} />
			<meta property={"og:description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1%20%281%29.jpg?v=2024-07-25T11:30:21.213Z) bottom/cover repeat scroll padding-box" padding="160px 0 160px 0">
			<Override slot="text">
				Welcome to Urban Fade
			</Override>
			<Override slot="text1" width="90%">
				We’re thrilled to welcome you to Urban Fade, a haven for those who appreciate the timeless art of grooming.{" "}
			</Override>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="box" />
			<Override slot="link" href="/services" background="--color-secondary">
				Our Offerings
			</Override>
		</Components.Hero>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Our skilled barbers are dedicated to providing an unparalleled experience, combining traditional techniques with modern styles. Whether you're here for a classic cut, a meticulous shave, or a refreshing grooming session, our goal is to make you look and feel your best.
					</Text>
					<Text margin="0px 0px 2rem 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						At Urban Fade, we pride ourselves on our rich heritage and commitment to excellence.
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Our establishment is more than just a place to get a haircut, it’s a community hub where stories are shared, friendships are forged, and confidence is cultivated. Each visit is an escape from the everyday hustle, offering a moment of relaxation and self-care. With a blend of vintage charm and contemporary flair, our barbershop stands as a testament to the enduring appeal of expert grooming.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						10+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						years of experience
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						2000+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						satisfied customers
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						20+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						service type
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 0px 0px 160px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
				order="1"
				text-align="right"
				align-self="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Why Choose Us?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Combining traditional barbering methods with modern innovations, we offer a unique and sophisticated grooming experience.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					More about our offerings
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
				justify-content="center"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Expert Barbers
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Our team consists of highly skilled professionals with years of experience, ensuring you receive top-notch service every time.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Quality Products
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We use only the best grooming products that nourish your hair and skin, leaving you feeling refreshed and revitalized.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Relaxing Environment
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Our barbershop offers a serene ambiance where you can unwind and enjoy your grooming session in comfort.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						4
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Personalized Service
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We take the time to understand your style preferences and grooming needs, delivering results that exceed expectations.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--light">
				Our Unique Approach
			</Text>
			<Text margin="0px 0px 2rem 0px" color="--light">
				What sets Urban Fade apart is our unwavering dedication to the craft. We believe that a great haircut is more than just a routine task – it’s a form of art. Our barbers are not only trained professionals but also passionate artists who view every haircut as a masterpiece in the making. From the moment you step through our doors, you'll notice the difference in the attention to detail, the personalized service, and the warm, welcoming atmosphere.
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				height="auto"
			>
				<Box min-width="100px" height="auto">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Haircuts
					</Text>
				</Box>
				<Box min-width="100px" height="auto">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Shaves
					</Text>
				</Box>
				<Box min-width="100px" height="auto">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Hair Treatments
					</Text>
				</Box>
			</Box>
			<Button
				margin="2rem 0px 0px 0px"
				box-shadow="10px 10px 10px -5px rgba(249, 242, 242, 0.1)"
				type="link"
				text-transform="uppercase"
				text-align="center"
				text-decoration-line="initial"
				border-width="1px"
				border-style="solid"
				href="/services"
			>
				<Strong>
					Our Services
				</Strong>
			</Button>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66a23551e778710018d2000a/images/7c4b420cb633bd2bb0ad8efa87e5bf12.jpg?v=2024-07-25T11:30:21.211Z) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Join Us Today
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="60%"
				>
					Ready to experience the best in grooming? Visit Urban Fade today, where tradition meets style. Our team is excited to welcome you and provide an exceptional grooming experience that you won’t forget. Step into a world of classic charm and modern elegance – because at Urban Fade, every cut is a cut above.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});